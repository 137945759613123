import React from 'react'
import * as styles from './EventCard.css'
import { Heading, Text } from 'theme-ui'
import Divider from '@components/Divider'


const DeadlineCard = props => {
  const { time, text, color } = props
  return (
    <div className='wrapper' style={styles.wrapper}>
        <div
          style={{
            position: 'relative',
            backgroundColor: '#fff',
            boxShadow: '-2px 2px 4px rgba(0, 0, 0, 0.25)',
            height: '228px',
            placeItems: 'start center',
            padding: '1rem',
            // textAlign: 'start center',
            borderRadius: '1rem'
          }}
        >
          <Heading variant='h2'>
            <Text
              className='author'
              sx={{ display: 'inline-block', color: color
            }}
            >
              {time}
            </Text>
          {/* <Divider /> */}
          </Heading>
            <Text
              sx={{ display: 'inline-block', color: '#2d2d2d'
            }}
            >
              {text}
            </Text>
        </div>
    </div>
  )
}

export default DeadlineCard
