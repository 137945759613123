import React from 'react'
import { Layout, Stack, Main, Hero, Sidebar } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Heading, Text, Button, Box } from 'theme-ui'
import SectionCard from '../components/EventCard/SectionCard'
import DeadlineCard from '../components/EventCard/DeadlineCard'
import * as stylesCss from './2022int.css'

export default props => {
  const data = useStaticQuery(technoart2022intQuerry)
  const partners2022 = []
  const sectionsImg = []
  for (const edge of data.year2022.edges) {
    partners2022.push(getImage(edge.node))
  }
  sectionsImg.push(getImage(data.section1))
  sectionsImg.push(getImage(data.section2))
  sectionsImg.push(getImage(data.section3))
  sectionsImg.push(getImage(data.section4))
  sectionsImg.push(getImage(data.section5))
  const image = getImage(data.hero)
  const styles = {
    wrapper: {
      marginTop: '1rem',
      marginBottom: '1rem',
      borderRadius: '1rem',
      alignItems: `left`,
      justifyContent: `center`,
      position: `relative`,
      zIndex: 2
    },
    wrapper_text: {
      marginLeft: '1rem',
      alignItems: `left`,
      justifyContent: `center`,
      position: `relative`,
      zIndex: 3
    },
    author: {
      display: `inline-block`,
      color: `alpha`
    },
    occupation: {
      mb: 2
    },
    specialty: {
      color: `text`,
      mb: 2
    }
  }
  return (
    <Layout {...props}>
      <Seo title='Contact' />
      <Hero sx={{ bg: `#b40c01` }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingTop: 64,
            paddingBottom: 64,
            aligntItems: 'center'
          }}
        >
          <Heading
            style={{
              color: '#ffffff',
              justifyContent: 'center',
              textAlign: 'center'
            }}
            variant='h1'
          >
            INTERNATIONAL TechnoArtKPI 2022
          </Heading>
          <Heading
            style={{
              color: '#ffffff',
              justifyContent: 'center',
              textAlign: 'center'
            }}
            variant='h3'
            sx={styles.specialty}
          >
            FESTIVAL IN THE FORMAT OF SOCIAL USEFUL INITIATIVES COMPETITION
            AMONG YOUNG PEOPLE <br />
          </Heading>
          <Heading
            style={{
              color: '#ffffff',
              justifyContent: 'center',
              textAlign: 'center'
            }}
            variant='h2'
            sx={styles.specialty}
          >
            "RESTORATION. RECONSTRUCTION. RENAISSANCE."
          </Heading>
        </div>
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <Img
            className='hero_image'
            style={stylesCss.hero_image}
            imgStyle={{ borderRadius: '1rem' }}
            image={image}
            loading='eager'
            layout='fullWidth'
            aspectRatio={3 / 1}
          />
          <Divider />
          <Heading variant='h1'>About Festival</Heading>
          {/* <Heading variant='h2'>Прориваємось у космос крізь онлайн</Heading> */}
          <Text>
            Ukraine is at war and this is the reality of today, the reality of
            the 21st century. And now, when Ukraine is reclaiming its lands from
            the occupants, there is an urgent need to restore the country. This
            process requires the involvement of both national and foreign
            experts, new ideas and ways to solve existing problems on the
            reconstruction of Ukraine.
            <br />
            <br />
            Ukraine will need the knowledge of experts in various fields, such
            as engineering, energy, economics, psychology, etc. The economy, our
            soil, houses, cities, bridges and roads will have to be restored.
            The restoration of psychological health of the whole nation, from
            children to the elderly, civilians and military, holds a special
            meaning.
            <br />
            <br />
            <strong>
              <u>The purpose of the Festival</u>
            </strong>
            is to unite young students from Ukraine and other countries by
            pursuing scientific innovations, to involve young people in the
            creative process and search for innovative and useful ideas in
            response to the real problems that have emerged as a result of
            hostilities in Ukraine.
            {/* <Divider /> */}
            <br />
            <br />
            <strong>
              <u>The mission</u>
            </strong>{' '}
            is to restore the world around you through your socially useful
            idea.
            <br />
            <br />
            <strong>
              <u>Main language of the Festival:</u>
            </strong>{' '}
            English.
            <br />
            <br />
            <strong>
              <u>Program of the Festival:</u>
            </strong>
            <br />
            <ul style={{ listStyleType: 'decimal' }}>
              <li>creation of a project/product within the offered sections</li>
              <li>
                informative online meetings with experts in the abovementioned
                subjects of the Festival (company representatives, professors
                and researchers from the universities of Ukraine and partner
                countries), exchange of opinions and ideas.
              </li>
            </ul>
            <Heading variant='h1'>Registration</Heading>
            <b>Complete registration form to take a part in Festival!</b>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 64,
                paddingBottom: 64
              }}
            >
              <div
                className='buttons_wrapper'
                style={stylesCss.buttons_wrapper}
              >
                <Button
                  sx={{ backgroundColor: '#b40c01', borderColor: '#b40c01' }}
                  as={Link}
                  target='_blank'
                  to='https://docs.google.com/forms/d/e/1FAIpQLSc8D-KG51rhgIx9PGJrGvgAVHoLdCmUoA16wJiVQdzVOGR2SA/viewform'
                >
                  Register now!
                </Button>
                <Button
                  sx={{ backgroundColor: '#b40c01', borderColor: '#b40c01' }}
                  as={Link}
                  to='/terms-and-conditions-of-participation-for-international-technoartkpi-2022'
                >
                  Terms and conditions of participation
                </Button>
              </div>
            </div>
            <Heading variant='h1'>Sections of the Festival</Heading>
            <Heading variant='h2'>Section tasks</Heading>
            <div className='events' style={styles.events}>
              <SectionCard
                section={sectionsImg[0]}
                slug={'/section-1.-environmental-engineering'}
                name={'Environmental engineering'}
              />
              <SectionCard
                section={sectionsImg[1]}
                slug={'/section-2.-environmental-restoration'}
                name={'Restoration of the environment'}
              />
              <SectionCard
                section={sectionsImg[2]}
                slug={'/section-3.-protection-and-security'}
                name={'Protection and Security'}
              />
              <SectionCard
                section={sectionsImg[3]}
                slug={'/section-4.-socio-economic-recovery'}
                name={'Socio-economic recovery'}
              />
              <SectionCard
                section={sectionsImg[4]}
                slug={'/section-5.-socio-psychological-recovery'}
                name={'Socio-psychological recovery'}
              />
            </div>
          </Text>
          <Divider />
          <Heading variant='h1'>Event calendar</Heading>
          <div className='events' style={styles.events}>
            <DeadlineCard
              color={'#1927d0'}
              time={'1.10-07.11.2022'}
              text={'Registration of participants.'}
            />
            <DeadlineCard
              color={'#f0c437'}
              time={'08.11.2022'}
              text={
                'The first joint online meeting with the participants of the Festival.'
              }
            />
            <DeadlineCard
              color={'#008f37'}
              time={'09.11, 16.11, 23.11, 30.11.2022'}
              text={
                'Online meetings with the experts (company representatives, professors and researchers from Ukraine and Poland).'
              }
            />
            <DeadlineCard
              color={'#1927d0'}
              time={'01.11 - 09.12.2022'}
              text={'Project implementation according to the chosen topic.'}
            />
            <DeadlineCard
              color={'#b40c01'}
              time={'09.12.2022 till 18:00 Kyiv time'}
              text={'Uploading projects to Google-form.'}
            />
            <DeadlineCard
              color={'#ff5200'}
              time={'12.12.-15.12.2022'}
              text={
                'Processing of projects by the experts and determination of the best project in each section.'
              }
            />
            <DeadlineCard
              color={'#95009b'}
              time={'16.12.2022'}
              text={
                'Closing ceremony of the Festival, announcement of winners.'
              }
            />
          </div>
          <Divider />
          <Heading variant='h1'>Partner Universities</Heading>
          {/* <Heading variant='h2'>TBA</Heading> */}
          <Box
            sx={{
              overflow: `hidden`,
              position: `relative`,
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            {partners2022.map(partner => (
              <Img
                style={{
                  borderRadius: '1rem',
                  marginRight: 16,
                  marginBottom: 32
                }}
                image={partner}
              />
            ))}
          </Box>
        </Main>
      </Stack>
    </Layout>
  )
}

const technoart2022intQuerry = graphql`
  query technoart2022intQuerry {
    year2022: allFile(
      filter: {
        absolutePath: { regex: "./partners/2022/(.*).(jpeg|jpg|gif|png)/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(height: 128)
          }
        }
      }
    }
    hero: file(
      absolutePath: { regex: "./2022int/2022int.(jpeg|jpg|gif|png)/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
    section1: file(
      absolutePath: { regex: "./2022int/sections/1.(jpeg|jpg|gif|png)/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
    section2: file(
      absolutePath: { regex: "./2022int/sections/2.(jpeg|jpg|gif|png)/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
    section3: file(
      absolutePath: { regex: "./2022int/sections/3.(jpeg|jpg|gif|png)/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
    section4: file(
      absolutePath: { regex: "./2022int/sections/4.(jpeg|jpg|gif|png)/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
    section5: file(
      absolutePath: { regex: "./2022int/sections/5.(jpeg|jpg|gif|png)/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
  }
`
