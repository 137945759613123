import React from 'react'
import * as styles from './EventCard.css'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { Button, Box, Heading, Text } from 'theme-ui'

const SectionCard = props => {
  const { section, name, slug } = props
  return (
    <Link sx={{ borderWidth: 0 }} className='wrapper' style={styles.wrapper} as={Link} to={slug}>
      <Box style={{ display: 'grid', borderRadius: '1rem' }}>
        <Img
          style={{
            gridArea: '1/1',
            borderRadius: '1rem',
            height: 256,
            width: '100%'
          }}
          imgStyle={{ borderRadius: '1rem' }}
          image={section}
          loading='eager'
          layout='fullWidth'
          aspectRatio={1 / 1}
        />{' '}
        <div
          style={{
            gridArea: '1/1',
            position: 'relative',
            placeItems: 'start center',
            display: 'grid',
            paddingTop: '0.5rem',
            paddingLeft: '0.5rem',
            textAlign: 'center'
          }}
        >
          <Heading variant='h2'>
            <Text
              className='author'
              sx={{ display: 'inline-block', color: '#fff',textShadow: '-1px -1px 0 #004770, 1px -1px 0 #004770, -1px 1px 0 #004770, 1px 1px 0 #004770'
            }}
            >
              {name}
            </Text>
          </Heading>
        </div>
      </Box>
    </Link>
  )
}

export default SectionCard
